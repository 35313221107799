import { Controller } from '@hotwired/stimulus'

function select(array, fn) {
  const res = []
  for (const i of array) {
    if (fn(i)) {
      res.push(i)
    }
  }

  return res
}

export default class extends Controller {
  static targets = ['locations', 'custom', 'plans', 'plan', 'specific', 'location']
  connect() {
    this.selected = new Set()
  }

  addLocation(_event) {
    const things = select(this.specificTargets, (x) => x.checked === true)
    const selectedLocations = things.map((x) => x.value).join(', ')

    this.cleanUpLocations()

    this.locationTarget.value =
      select([this.locationTarget.value, selectedLocations], (x) => x.length > 0)
        .join(', ')
  }

  cleanUpLocations() {
    let locations = this.locationTarget.value

    for (const i of select(this.specificTargets, (x) => x.checked === true)) {
      locations = locations.replace(`${i.value}`, '')
    }
    locations = locations.replace(/[, ]*$/, '')
    this.locationTarget.value = locations
  }

  toggle(event) {
    const type = event.params.type
    const checked = event.target.checked

    if (checked) {
      this.selected.add(type)
    } else {
      this.selected.delete(type)
    }

    if (!this.selected.has('houseland')) {
      this.cleanUpLocations()
      this.specificTargets.forEach((x) => { x.checked = false })
    }

    this.locationsTarget.classList.add('hidden')
    this.customTarget.classList.add('hidden')
    this.plansTarget.classList.add('hidden')

    if (this.selected.has('kitset')) {
      this.customTarget.classList.remove('hidden')
      this.plansTarget.classList.remove('hidden')
      for (const p of Array.from(this.planTargets)) {
        if (p.dataset['building-type'] === 'kitset-homes') {
          p.classList.remove('!hidden')
        } else {
          p.classList.add('!hidden')
        }
      }
    } else if (this.selected.has('transportable')) {
      this.customTarget.classList.remove('hidden')
      this.plansTarget.classList.remove('hidden')
      for (const p of Array.from(this.planTargets)) {
        if (p.dataset['building-type'] === 'transportable-homes') {
          p.classList.remove('!hidden')
        } else {
          p.classList.add('!hidden')
        }
      }
    }

    if ((!this.selected.has('kitset') && !this.selected.has('transportable')) && this.selected.has('houseland')) {
      this.locationsTarget.classList.remove('hidden')
    }
  }
}
